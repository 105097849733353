import dayjs from "dayjs"
import { graphql } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import React, { useEffect, useLayoutEffect } from "react"
import { useRef } from "react"
import { useState } from "react"
import SimpleReactLightbox, {
  SRLWrapper,
  useLightbox,
} from "simple-react-lightbox"
import Layout from "../components/layout"

dayjs.locale("tr")

export default function SergiSayfasi({ data }) {
  const { language } = useI18next()
  const [index, setIndex] = useState()

  useLayoutEffect(() => {
    let resimID = window.location.hash.split("#resim-")[1]
    if (resimID) {
      resimID = Number(resimID)
      setIndex(resimID)
    }
  }, [])

  const postData = data.markdownRemark.frontmatter
  let title
  if (language === "en") {
    dayjs.locale("en")
    title = postData.title_en
  } else {
    dayjs.locale("tr")
    title = postData.title
  }

  return (
    <Layout>
      <SimpleReactLightbox>
        <div className="gallery-container">
          <div className="gallery-all">
            <div className="gallery">
              <p className="gallery-date">
                {dayjs(postData.datetime).format("D MMMM, YYYY")}
              </p>
              <div className="gallery-title">
                <img src={postData.baslik_image} alt={title} />
                {title}
              </div>
              <Slides
                postData={postData}
                title={title}
                language={language}
                initialIndex={index}
              />
            </div>
          </div>
        </div>
      </SimpleReactLightbox>
    </Layout>
  )
}

const Slides = ({ postData, title, language, initialIndex, ...rest }) => {
  const { openLightbox } = useLightbox()
  const btn = useRef()

  useEffect(() => {
    if (initialIndex) {
      Promise.all(
        Array.from(document.images).map(img => {
          if (img.complete) return Promise.resolve(img.naturalHeight !== 0)
          return new Promise(resolve => {
            img.addEventListener("load", () => resolve(true))
            img.addEventListener("error", () => resolve(false))
          })
        })
      ).then(results => {
        if (results.every(res => res)) {
          console.log("all images loaded successfully")
          setTimeout(() => {
            btn.current.click()
          }, 300)
        } else console.log("some images failed to load, all finished loading")
      })
    }
  }, [initialIndex])

  function handleSlideChange({ index }) {
    window.location.hash = `#resim-${index + 1}`
  }

  return (
    <>
      <button
        hidden
        ref={x => (btn.current = x)}
        onClick={() => initialIndex > -1 && openLightbox(initialIndex - 1)}
      />
      <SRLWrapper callbacks={{ onSlideChange: handleSlideChange }}>
        {postData.sergi_resimleri?.map((kg, i) => {
          return (
            <a href={kg.resim} key={kg + i}>
              <img
                src={kg.thumbnail || kg.resim}
                alt={language === "en" ? kg.baslik_en : kg.baslik}
              />
            </a>
          )
        })}
      </SRLWrapper>
    </>
  )
}

export const pageQuery = graphql`
  query SergiQuery($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        title_en
        baslik_image
        datetime
        sergi_resimleri {
          resim
          thumbnail
          baslik
          baslik_en
        }
      }
    }
  }
`
